import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Masthead from "../components/Masthead"
import LeadRequestBlock from "../components/LeadRequestBlock"

import { content } from "../../content/index.js"
import ThreeColumnInfoBlock from "../components/ThreeColumnInfoBlock"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Masthead
      title={content.masthead.title}
      subtitle={content.masthead.subtitle}
    />
    <ThreeColumnInfoBlock
      title={content.infoBlock.title}
      blockContent={content.infoBlock.blockContent}
    />
    <ThreeColumnInfoBlock
      title={content.pricingBlock.title}
      blockContent={content.pricingBlock.blockContent}
    />
    <LeadRequestBlock />
  </Layout>
)

export default IndexPage
