import React from "react"
import { StyleSheet, css } from "aphrodite"

const ThreeColumnInfoBlock = props => {
  const blocks = props.blockContent.map(content => (
    <div className="one-third column">
      <div className={css(styles.center)}>
        <b>{content.title}</b>
        <p>{content.subtitle}</p>
        {!!content.body ? <p>{content.body}</p> : null}
      </div>
    </div>
  ))
  return (
    <div className="container">
      <div className="row">
        <h4>{props.title}</h4>
      </div>
      <div className="row">{blocks}</div>
    </div>
  )
}

const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})

export default ThreeColumnInfoBlock
