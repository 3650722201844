import React from "react"

const LeadRequestBlock = () => {
  return (
    <form
      className="container leadRequestBlock"
      name="request"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/success"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="request" />
      <div className="row">
        <label htmlFor="exampleEmailInput">Sign up for the mailing list</label>
        <input
          className="u-full-width"
          type="text"
          name="email"
          type="email"
          placeholder="test@mailbox.com"
          id="exampleEmailInput"
          required
        />
      </div>
      <div className="row">
        <input className="button-primary" type="submit" value="Submit" />
      </div>
    </form>
  )
}

export default LeadRequestBlock
