export const content = {
  masthead: {
    title: "hiringtrends.tech",
    subtitle:
      "A monthly mailing list that surfaces exponential growth in tech jobs on a city-by-city basis",
  },
  infoBlock: {
    title: "Use cases",
    blockContent: [
      {
        title: "Real estate",
        subtitle:
          "Use hiringtrends.tech to track where to open your next office",
        imagePath: "/image",
      },
      {
        title: "Tech investing",
        subtitle:
          "Learn how Blank Industries leveraged hiringtrends.tech to track leading indicators of economic growth.",
        imagePath: "/image",
      },
      {
        title: "Job search",
        subtitle:
          "hiringtrends.tech informed Blank Industries of overlooked metropolitan areas with a thriving developer community.",
        imagePath: "/image",
      },
    ],
  },
  pricingBlock: {
    title: "Pricing",
    blockContent: [
      {
        title: "$0/month",
        subtitle: "Starter",
        body:
          "Get three cities where tech hiring has increased the most in that month",
      },
      {
        title: "$10/month",
        subtitle: "Essentials",
        body:
          "See the top ten cities where tech hiring has increased the most, broken down by function",
      },
      {
        title: "$100/month",
        subtitle: "Pro",
        body: "All of our data",
      },
    ],
  },
}
