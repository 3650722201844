import React from "react"

const Masthead = (props) => {
  return (
    <div className="container">
      <div className="row masthead">
        <h1 className="mastheadHeading">
          {props.title}
        </h1>
        <p className="mastheadSubtitle">
          {props.subtitle}
        </p>
      </div>
    </div>
  )
}

export default Masthead;